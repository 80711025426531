




















































































































































import Vue from "vue";
import { mapState } from "vuex";
import { Dialog, Toast, Notify } from "vant";
import { dispatch, ListQuery, Order } from "@/store";
import { OrderStatus } from "@/enum";
import { url } from "@/api";
import qr from "@/assets/images/wechat_work_qr_code.png";

export default Vue.extend({
  data() {
    return {
      OrderStatus,
      tabs: {
        "": "全部订单",
        unpaid: OrderStatus.unpaid,
        paid: "待发货",
        shipping: OrderStatus.shipping,
        confirmed: OrderStatus.confirmed,
      },
      loading: false,
      status: "",
      order: {} as Order,
    };
  },
  computed: {
    ...mapState("orders", ["list", "query", "count"]),
    ...mapState(["settings"]),
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .ordersGetList({
          ...query,
          status: this.status,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onTabsChange(status: string) {
      this.status = status;
      this.$router.replace({
        path: this.$route.path,
        query: { status },
      });
      this.onRefresh();
    },
    totalNumber(order: Order) {
      let number = 0;
      order.sub_orders.map((item) => {
        number += item.number;
      });
      return number;
    },
    addressVisible(order: Order) {
      const index = order.sub_orders.findIndex(
        (subOrder) =>
          subOrder.status === "paid" && !subOrder.address_info.edit_num
      );
      return index >= 0 && this.settings.address.edit_addr;
    },
    refundVisible(order: Order) {
      const paidSubOrders = order.sub_orders.filter(
        (subOrder: any) => subOrder.status === "paid"
      );
      return (
        order.order.status === "paid" &&
        order.sub_orders.length === paidSubOrders.length &&
        !order.order.is_coin_pay &&
        this.settings.refund.is_open
      );
    },
    onOrderClick(order: Order) {
      this.$router.push(this.$paths.orders + "/" + order.order.order_no);
    },
    customVisible(order: Order) {
      const customSubOrders = order.sub_orders.filter((subOrder: any) =>
        [
          522,
          524,
          525,
          3519,
          3520,
          3521,
          3457,
          3464,
          4167,
          4168,
          4324,
          4325,
          4462,
          4463,
          4570,
          4571,
          4573,
          4576,
          4577,
          4578,
        ].includes(subOrder.sku_id)
      );
      return order.order.status === "paid" && customSubOrders.length;
    },
    onCustomClick(order: Order) {
      window.open(
        url.AM65LessCustom + "?order_no=" + order.order.order_no,
        "_blank"
      );
    },
    onaddressChange(order: Order) {
      this.$router.push(this.$paths.orders + "/" + order.order.order_no);
      setTimeout(() => {
        const subOrder = order.sub_orders.find(
          (subOrder) =>
            subOrder.status === "paid" && !subOrder.address_info.edit_num
        );
        if (subOrder) {
          Notify("收货地址只可修改一次，请仔细检查确认");
          this.$router.replace({
            path: this.$paths.addresses,
            query: {
              redirect: this.$route.fullPath,
              address: String(subOrder.address_info.id),
            },
          });
        } else {
          Notify("您已修改过收货地址，如有疑问请联系客服");
        }
      }, 100);
    },
    onStatusChange(order: Order, status: string) {
      switch (status) {
        case "closed":
          Dialog.confirm({
            title: "提示",
            message: `确认取消订单？`,
          }).then(() => {
            dispatch
              .ordersStatusPatch({
                ...order,
                order: {
                  ...order.order,
                  status,
                },
              })
              .then(() => {
                Toast("取消订单成功");
                this.onRefresh();
              })
              .catch(() => {
                Toast("订单取消失败");
                this.onRefresh();
              });
          });
          break;
        case "refunding":
          Dialog.confirm({
            title: "申请退款",
            confirmButtonText: "立即申请",
            message: `您的退款预计 7 个工作日内会原路退回您的付款账户，请留意查收！<br /><br /><img style="width: auto; height: 1.5rem"src="${qr}"/><br />若有疑问或申请被拒绝请联系客服处理`,
            allowHtml: true,
          }).then(() => {
            dispatch
              .ordersStatusPatch({
                ...order,
                order: {
                  ...order.order,
                  status,
                },
              })
              .then(() => {
                Toast("已申请退款");
                this.onRefresh();
              });
          });
          break;
      }
    },
    onPhoto(order: Order) {
      if (order.sub_orders.length === 1) {
        this.$router.push(`/order/{id}/photo/{productId}`);
      } else {
        this.$router.push(`/order/{id}/products/`);
      }
    },
  },
  watch: {
    $route(to) {
      if (this.status !== to.query.status) {
        this.status = to.query.status;
        this.onRefresh();
      }
    },
  },
  mounted() {
    this.status = String(this.$route.query.status || this.status);
    this.onRefresh();
  },
});
